import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false, title: 'iidokoro | 情報処理技術者試験専用の学習記録サービス', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { requiresAuth: true, title: 'iidokoro', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/TermsOfService.vue'),
    meta: { requiresAuth: false, title: '利用規約 | iidokoro', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: { requiresAuth: false, title: 'プライバシーポリシー | iidokoro', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: { requiresAuth: false, title: 'コンタクト | iidokoro', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false, title: 'ログイン | iidokoro', desc: 'まだ一人で情報処理の資格勉強を頑張りますか？iidokoroはSNSのような情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので同じ資格を勉強している仲間が簡単に見つけられます。' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// ログイン済か確認
function isLogin() {
  return store.getters['user/isAuth']
}

// メタフィールドrequiresAuthが要認証trueなら認証フラグ判定
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // requiresAuthがtrueなら評価
    if (!isLogin()) {
      // 未ログインならログインページへ
      next('/')
    } else {
      next() // スルー
    }
  } else {
    next() // スルー
  }
})

export default router
