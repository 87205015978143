<template>
  <v-card>
    <v-card-title class="grey lighten-2"> 検索条件を設定する </v-card-title>

    <v-card-text class="pb-0">
      <v-radio-group v-model="radioValue" class="mt-2">
        <v-radio
          v-for="data in radioList"
          :key="data.value"
          :label="data.label"
          :value="data.value"
        ></v-radio>
      </v-radio-group>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeDialog"> 閉じる </v-btn>
      <v-btn color="primary" text @click="changeCond"> 検索する </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import define from "../Define";
import util from "../utils/util";
import { mapActions } from "vuex";
export default {
  data: function () {
    return {
      radioValue: "",
      radioList: [{ label: "全ての資格", value: null }].concat(
        define.CERTIFICATION_LIST
      ),
    };
  },
  created() {
    this.radioValue = this.$store.getters["learn/searchCond"];
  },
  methods: {
    ...mapActions("learn", ["setCond"]),
    closeDialog() {
      this.$emit("closeDialog");
    },
    changeCond() {
      this.setCond(this.radioValue);
      this.$emit("closeDialogAndUpdate");
    },
  },
};
</script>
