<template>
  <v-app class="app-color">
    <v-navigation-drawer v-model="drawer" app temporary height="100%">
      <v-list-item link to="/" class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="title"
            ><v-img
              max-height="150"
              min-width="256"
              src="../src/assets/iidokoro_Logo_02.png"
            ></v-img
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/profile" v-show="isAuth">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>プロフィール</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/contact">
          <v-list-item-icon>
            <v-icon>mdi-contacts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>コンタクト</v-list-item-title>
        </v-list-item>
        <!-- フェーズ3で実装 -->
        <!-- <v-list-item link to="/ranking">
          <v-list-item-icon>
            <v-icon>mdi-pencil-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>参考書情報</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false" prepend-icon="mdi-text-box-multiple">
          <template v-slot:activator>
            <v-list-item-title>試験ごと</v-list-item-title>
          </template>
          <v-list-item link v-for="data of navigationList" :key="data.chipText" :to="data.link">
            <v-avatar class="mr-5" :color="data.color" size="32" dark>
              <span class="white--text">{{ data.chipText }}</span>
            </v-avatar>
            <v-list-item-title>
              {{ data.linkText }}
            </v-list-item-title>
          </v-list-item> 
        </v-list-group>-->
      </v-list>
      <template v-slot:append>
        <div class="version-text">ver1.0.0</div>
        <v-divider></v-divider>
        <div class="pa-2" v-show="!isAuth">
          <v-btn block to="/login" dark color="primary"> Login </v-btn>
        </div>
        <div class="pa-2" v-show="isAuth">
          <v-btn block @click="logout" dark color="primary"> Logout </v-btn>
        </div>
        <div class="pa-2">
          <v-btn block to="/terms">利用規約</v-btn>
        </div>
        <div class="pa-2">
          <v-btn block to="/privacy">プライバシーポリシー</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app outlined flat width="100vw" max-width="100vw">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <router-link to="/" class="toolbar-title"
          ><v-img
            max-height="40"
            max-width="220"
            contain
            src="../src/assets/iidokoro_Logo_01.png"
          ></v-img
        ></router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom min-width="360" max-height="240" left :offset-x="offset">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="updateRead">
            <v-badge :value="badgeShow" color="red" overlap dot>
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in alertList" :key="item.id">
            <v-list-item-content>
              <v-list-item-title
                >{{ item.name }}さんが応援しています！</v-list-item-title
              >
              <v-list-item-subtitle>{{
                getDatetime(item.created_at)
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon v-show="!item.is_read">
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-show="item.is_read">
              <v-icon>mdi-email-open</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item v-show="alertList.length == 0 && isAuth">
            <v-list-item-content>
              <v-list-item-title>まだ通知がありません。</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-show="!isAuth">
            <v-list-item-content>
              <v-list-item-title
                >いいねをもらうとここに通知が表示されます。</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <div class="app-width">
        <router-view />
        <AlertItem />
      </div>
    </v-main>
  </v-app>
</template>

<style scoped>
.app-width {
  width: 100vw;
  height: 100%;
  background-color: #f5f5f5 !important;
}
.app-color {
  background-color: #f5f5f5 !important;
  width: 100vw;
}
.toolbar-title {
  color: inherit;
  text-decoration: none;
}
.readed_color {
  background-color: "#B0BEC5";
}
.version-text {
  text-align: center;
}
</style>

<script>
import { mapActions } from "vuex";
import AlertItem from "./components/AlertItem";
import define from "./Define";
import db from "./lib/db";
// import { learnList, updateLearnList, addLearnList } from '../lib/learn'
import { getAlertList } from "./lib/alert";
export default {
  name: "App",
  components: {
    AlertItem,
  },
  data: () => {
    return {
      drawer: false,
      navigationList: [
        {
          chipText: "IP",
          linkText: "ITパスポート",
          color: "yellow darken-1",
          link: "/ipbook",
        },
        {
          chipText: "SG",
          linkText: "情報セキュリティマネジメント",
          color: "blue darken-1",
          link: "/sgbook",
        },
        {
          chipText: "FE",
          linkText: "基本情報技術者",
          color: "pink darken-1",
          link: "/febook",
        },
        {
          chipText: "AP",
          linkText: "応用情報技術者",
          color: "orange darken-3",
          link: "/apbook",
        },
        {
          chipText: "ST",
          linkText: "ITストラテジスト",
          color: "blue-grey darken-1",
          link: "/stbook",
        },
        {
          chipText: "SA",
          linkText: "システムアーキテクト",
          color: "red darken-2",
          link: "/sabook",
        },
        {
          chipText: "PM",
          linkText: "プロジェクトマネージャ",
          color: "purple darken-1",
          link: "/pmbook",
        },
        {
          chipText: "NW",
          linkText: "ネットワークスペシャリスト",
          color: "amber darken-3",
          link: "/nwbook",
        },
        {
          chipText: "DB",
          linkText: "データベーススペシャリスト",
          color: "green darken-1",
          link: "/dbbook",
        },
        {
          chipText: "ES",
          linkText: "エンベデッドシステムスペシャリスト",
          color: "cyan darken-1",
          link: "/esbook",
        },
        {
          chipText: "SM",
          linkText: "ITサービスマネージャ",
          color: "grey darken-1",
          link: "/smbook",
        },
        {
          chipText: "AU",
          linkText: "システム監査技術者",
          color: "grey darken-3",
          link: "/aubook",
        },
        {
          chipText: "SC",
          linkText: "情報安全確保支援士",
          color: "light-blue darken-4",
          link: "/scbook",
        },
      ],
      items: [
        { name: "TEST1", is_read: false },
        { name: "TEST2", is_read: false },
        { name: "TEST3", is_read: true },
        { name: "TEST4", is_read: true },
      ],
      offset: true,
      alertList: [],
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["user/isAuth"];
    },
    badgeShow() {
      return this.$store.getters["user/badgeShow"];
    },
  },
  methods: {
    ...mapActions("user", ["signOut", "setAlert"]),
    ...mapActions("views", ["setSnack"]),
    ...mapActions("learn", ["setCond"]),
    logout() {
      this.signOut();
      // alertもリセット
      this.initAlert();
      this.$store.dispatch("user/updateBadgeShow", false);
      // ログアウト時に再度データを取得する
      //home画面にあるmethodを実行する
      this.$root.$refs.Home.updateList();
      this.setSnack(define.MESSAGE_LOGOUT_SUCCESS);
      this.$router.push("/").catch((err) => {});
    },
    getDatetime(date) {
      if (!date) {
        return "";
      } else {
        return new Date(date.seconds * 1000 + date.nanoseconds / 100000)
          .toISOString()
          .substr(0, 10);
      }
    },
    async updateRead() {
      const list = await getAlertList();
      const uid = this.$store.getters["user/uid"];
      this.setAlert(uid);
      list.forEach(async (element) => {
        if (!element.is_read) {
          this.$store.dispatch("user/updateBadgeShow", false);
          await db
            .collection("user")
            .doc(uid)
            .collection("alert")
            .doc(element.id)
            .update({
              is_read: true,
            })
            .then(() => {
              // this.setAlert(uid)
            });
        }
      });
    },
    createTitleDesc: function (routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title = "iidokoro";
      }

      // メタタグdescription設定
      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc + " | iidokoro";
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "IPA資格の学習記録サービスです。");
      }
    },
    async getAlert() {
      this.alertList = await getAlertList();
    },
    initAlert() {
      this.alertList = [];
    },
  },
  async created() {
    //他コンポーネントからもこのコンポーネントのメソッドを呼び出せるように設定
    this.$root.$refs.App = this;
    // await getAlertList()
  },
  mounted: async function () {
    this.alertList = await getAlertList();
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch: {
    $route(routeInstance, from) {
      this.createTitleDesc(routeInstance);
    },
  },
};
</script>
