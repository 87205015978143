import firebase from '../lib/firebase'
import auth from '../lib/auth'
import db from '../lib/db'

export const user = {
  namespaced: true,
  state: {
    isAuth: false,
    displayName: '',
    email: '',
    photoURL: '',
    uid: '',
    learn: '',
    user: null,
    badgeShow: false,
    alertList: [],
  },
  getters: {
    isAuth: (state) => state.isAuth,
    uid: (state) => state.uid,
    photoURL: (state) => state.photoURL,
    displayName: (state) => state.displayName,
    learn: (state) => state.learn,
    badgeShow: (state) => state.badgeShow,
    alertList: (state) => state.alertList,
  },
  mutations: {
    /**
     * 認証情報の設定
     * @param {state} state : 変更対象
     * @param {Object} user : 認証判定結果
     */
    setSignInState(state, user) {
      state.isAuth = !!user
      state.uid = (user && user.uid) || ''
      state.displayName = (user && user.displayName) || ''
      state.photoURL = (user && user.photoURL) || ''
    },
    setDisplayName(state, name) {
      state.displayName = name
    },
    setLearn(state, learn) {
      state.learn = learn
    },
    setUser(state, user) {
      state.user = user
    },
    async setAlert(state, uid) {
      // if (!uid) {
      //   state.alertList = []
      //   state.badgeShow = false
      // } else {
      //   // 一旦リセット
      //   state.alertList = []
      //   state.badgeShow = false
      //   await db
      //     .collection('user')
      //     .doc(uid)
      //     .collection('alert')
      //     .orderBy('created_at', 'desc')
      //     .limit(30)
      //     .get()
      //     .then((snapShot) => {
      //       snapShot.forEach(async (doc) => {
      //         const data = doc.data()
      //         state.alertList.push({ id: doc.id, ...doc.data() })
      //         if (!this.badgeShow && !data.is_read) {
      //           state.badgeShow = true
      //         }
      //       })
      //     })
      // }
    },
    updateBadgeShow(state, flg) {
      state.badgeShow = flg
    },
  },
  actions: {
    /**
     * signin
     * @param {} param0
     */
    async signIn({ commit }, { user }) {
      commit('setSignInState', user)
    },
    /**
     * signout
     * @param {} param0
     */
    async signOut({ commit }) {
      await firebase
        .auth()
        .signOut()
        .then((res) => {
          commit('setSignInState', false)
          commit('setAlert', '')
        })
    },
    async checkAuth({ commit }) {
      await auth().then((user) => commit('setSignInState', user))
    },
    setDisplayName({ commit }, name) {
      commit('setDisplayName', name)
    },
    setLearn({ commit }, learn) {
      commit('setLearn', learn)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setAlert({ commit }, uid) {
      commit('setAlert', uid)
    },
    updateBadgeShow({ commit }, flg) {
      commit('updateBadgeShow', flg)
    },
  },
}
