<template>
  <v-sheet class="content-height">
    <v-card v-show="!isAuth">
      <v-card-title>ようこそ！iidokoroへ！</v-card-title>
      <v-card-text
        >iidokoroは情報処理技術者試験専用の学習記録サービスです。情報処理技術者試験専用なので学習を記録するだけで、同じ資格を勉強している仲間が簡単に見つけられ、モチベーションがアップします。</v-card-text
      >
      <v-row
        justify="center"
        align-content="space-around"
        class="sign_in_button"
      >
        <v-btn to="/login" color="primary" class="mb-2" dark
          >サインインして使ってみる(無料)</v-btn
        >
      </v-row>
    </v-card>

    <v-dialog v-model="searchDialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="center" class="ma-0">
          <v-btn v-bind="attrs" v-on="on" class="my-6" color="primary" dark
            ><v-icon left dark> mdi-magnify </v-icon>検索条件を変更する</v-btn
          >
        </v-row>
      </template>
      <SearchDataModal
        @closeDialog="closeSearchDialog"
        @closeDialogAndUpdate="closeSearchDialogAndUpdate"
      />
    </v-dialog>

    <v-row justify="center" align-content="center" class="mx-0 mb-2 mt-0">
      <div>{{ this.searchCond }}</div>
    </v-row>
    <v-divider></v-divider>

    <div v-if="noDataFlg && !skeletonDisp" v-cloak class="content-width">
      <v-row
        justify="center"
        align-content="center"
        class="mt-6 mx-0 content-width"
      >
        <v-icon x-large>mdi-book-open-page-variant-outline</v-icon>
      </v-row>
      <v-row
        justify="center"
        align-content="center"
        class="mt-4 mx-0 content-width"
      >
        <div>まだこの資格を勉強中の人がいません。</div>
        <div>あなたが最初の登録者になってみませんか？</div>
      </v-row>
    </div>
    <div v-if="this.skeletonDisp">
      <SkeletonLoaderItem />
    </div>
    <div v-for="cardData in list" :key="cardData.id">
      <CardItem :cardData="cardData" />
    </div>

    <div v-if="this.skeletonDisp">
      <SkeletonLoaderItem />
    </div>

    <div class="mt-8 mb-4 content-width" v-show="this.addDataFlg">
      <v-row justify="center" class="mx-0 mb-2 mt-0">
        <v-btn @click="addList">さらに取得する</v-btn>
      </v-row>
    </div>

    <div class="mt-6 btn_margin"></div>

    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-fab-transition>
          <v-btn
            color="primary"
            dark
            right
            fab
            bottom
            fixed
            v-bind="attrs"
            v-on="{ ...menu }"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <CreateDataModal
        @closeDialog="closeCreateDialog"
        @closeDialogAndUpdate="closeCreateDialogAndUpdate"
      />
    </v-dialog>
  </v-sheet>
</template>

<style scoped>
.content-height {
  background-color: #f5f5f5;
}
.card-width {
  width: 92%;
}
.action_btn {
  bottom: 0;
  position: absolute;
  margin: 0 32px 32px 0px;
}
.btn_location {
  bottom: 64px !important;
  right: 64px !important;
}
[v-cloak] {
  display: none;
}
.btn_margin {
  height: 64px;
}
.sign_in_button {
  width: 100vw;
  margin: 0px !important;
}
.content-width {
  width: 100%;
}
</style>

<script>
import CardItem from "../components/CardItem";
import CreateDataModal from "../components/CreateDataModal";
import SearchDataModal from "../components/SearchDataModal";
import SkeletonLoaderItem from "../components/SkeletonLoaderItem";
import util from "../utils/util";
import { mapActions } from "vuex";
import { learnList, updateLearnList, addLearnList } from "../lib/learn";

export default {
  name: "Home",
  data: () => {
    return {
      dialog: false,
      searchDialog: false,
      loadingFlg: false,
      btnToolTipShow: true,
      list: [],
    };
  },
  components: {
    CardItem,
    CreateDataModal,
    SearchDataModal,
    SkeletonLoaderItem,
  },
  async created() {
    //他コンポーネントからもこのコンポーネントのメソッドを呼び出せるように設定
    this.$root.$refs.Home = this;
    // let cond = this.$store.getters['learn/searchCond']
    this.list = await updateLearnList();
  },
  computed: {
    listData: {
      get() {
        // return this.$store.getters['learn/list']
        return learnList;
      },
    },
    skeletonDisp: {
      get() {
        return this.$store.getters["learn/skeletonDisp"];
      },
    },
    addDataFlg: {
      get() {
        return this.$store.getters["learn/addDataFlg"];
      },
    },
    isAuth() {
      return this.$store.getters["user/isAuth"];
    },
    searchCond() {
      return util.getCertificationLebel(
        this.$store.getters["learn/searchCond"]
      );
    },
    listNum() {
      return this.$store.getters["learn/listNum"];
    },
    noDataFlg() {
      return this.$store.getters["learn/noDataFlg"];
    },
  },
  methods: {
    ...mapActions("learn", ["setCond", "setList"]),
    closeCreateDialog() {
      this.dialog = false;
    },
    closeSearchDialog() {
      this.searchDialog = false;
    },
    async updateList() {
      this.list = [];
      this.list = await updateLearnList();
    },
    async closeCreateDialogAndUpdate() {
      this.dialog = false;
      this.list = await updateLearnList();
    },
    async closeSearchDialogAndUpdate() {
      this.searchDialog = false;
      this.list = await updateLearnList();
    },
    async addList() {
      this.list = await addLearnList();
    },
  },
  destroyed() {
    this.setList([]);
  },
};
</script>
