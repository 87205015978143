<template>
  <div class="AlertItem">
    <div class="text-center ma-2">
      <v-snackbar v-model="setSnack" :color="color" :timeout="time">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="resetSnack">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import define from '../Define'
  export default {
    data: () => {
      return {
        alertText: '',
        alertColor: '',
        alertDisp: false,
      }
    },
    computed: {
      text() {
        return this.$store.getters['views/snackText']
      },
      color() {
        return this.$store.getters['views/snackColor']
      },
      time() {
        return this.$store.getters['views/snackTime']
      },
      setSnack: {
        get() {
          return !!this.text
        },
        set(val) {
          this.resetSnack()
          return val
        },
      },
    },
    methods: {
      resetSnack() {
        //mapacrionの仕様上、エラーとなるためdispatchで対応
        return this.$store.dispatch('views/setSnack', define.MESSAGE_DEFAULT)
      },
    },
  }
</script>
