// Firebase認証処理
// import firebase from 'firebase'
import 'firebase/firebase-auth'

function auth() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(user || false)
    })
  })
}
export default auth
