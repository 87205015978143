import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#194292',
        secondary: '#7ecef4',
        accent: colors.pink,
      },
      dark: {
        primary: '#194292',
        secondary: '#7ecef4',
        accent: colors.pink,
      },
    },
  },
})
