import firebase from '../lib/firebase'
import auth from '../lib/auth'
import db from '../lib/db'

export const views = {
  namespaced: true,
  state: {
    snackText: '',
    snackColor: '',
    snackTime: '',
  },
  getters: {
    snackText: (state) => state.snackText,
    snackColor: (state) => state.snackColor,
    snackDisp: (state) => state.snackDisp,
  },
  mutations: {
    setSnack(state, { text, color, time }) {
      state.snackColor = color
      state.snackTime = time
      state.snackText = text
    },
  },
  actions: {
    setSnack({ commit }, data) {
      commit('setSnack', data)
    },
  },
}
