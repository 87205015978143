import db from './db'
import store from '../store/index'
import define from '../Define'

export let learnList = []

export const updateLearnList = async () => {
  learnList = []
  store.dispatch('learn/updateSkeletonDisp', true)
  store.dispatch('learn/updateNoDataFlg', false)
  store.dispatch('learn/updateListNum', 0)
  store.dispatch('learn/updateAddDataFlg', false)
  const searchCond = store.getters['learn/searchCond']

  //検索条件変更 + データも取得する
  let list = []
  let likeList = []
  let listNum = 0
  if (!searchCond) {
    await db
      .collection('learn')
      .orderBy('created_at', 'desc')
      .limit(define.DATA_MAX)
      .get()
      .then(async (snapShot) => {
        let size
        let sizeData = 0
        for (let i = 0; i < snapShot.size; i++) {
          let doc = snapShot.docs[i]
          await db
            .collection('learn')
            .doc(doc.id)
            .collection('likedUsers')
            .get()
            .then((snapshotLike) => {
              likeList = []
              for (let j = 0; j < snapshotLike.size; j++) {
                let docLike = snapshotLike.docs[j]
                likeList.push({ ...docLike.data() })
              }
              size = snapshotLike.size
            })
          let data = { id: doc.id, size: size, ...doc.data(), likeList: likeList }
          list.push(data)
          store.dispatch('learn/updateListNum', listNum)
          sizeData += 1
          if (sizeData === define.DATA_MAX) {
            store.dispatch('learn/updateAddDataFlg', true)
            store.dispatch('learn/updateDataIndex', snapShot.docs[snapShot.docs.length - 1])
          }
        }
      })
  } else {
    await db
      .collection('learn')
      .where('certification', '==', searchCond)
      .orderBy('created_at', 'desc')
      .limit(define.DATA_MAX)
      .get()
      .then(async (snapShot) => {
        let size
        let sizeData = 0
        let listNum = 0
        //forEachは非同期処理のためfor文で処理記載
        for (let i = 0; i < snapShot.size; i++) {
          let doc = snapShot.docs[i]
          await db
            .collection('learn')
            .doc(doc.id)
            .collection('likedUsers')
            .get()
            .then((snapshotLike) => {
              likeList = []
              for (let j = 0; j < snapshotLike.size; j++) {
                let docLike = snapshotLike.docs[j]
                likeList.push({ ...docLike.data() })
              }
              size = snapshotLike.size
            })
          let data = { id: doc.id, size: size, ...doc.data(), likeList: likeList }
          list.push(data)
          store.dispatch('learn/updateListNum', listNum)
          sizeData += 1
          if (sizeData === define.DATA_MAX) {
            store.dispatch('learn/updateAddDataFlg', true)
            store.dispatch('learn/updateDataIndex', snapShot.docs[snapShot.docs.length - 1])
          }
        }
      })
  }
  store.dispatch('learn/updateSkeletonDisp', false)
  learnList = list
  if (list.length === 0) {
    store.dispatch('learn/updateNoDataFlg', true)
  }
  return list
}

export const addLearnList = async () => {
  // state.addDataFlg = false
  // state.skeletonDisp = true
  let resultList = learnList
  store.dispatch('learn/updateAddDataFlg', false)
  store.dispatch('learn/updateSkeletonDisp', true)
  const searchCond = store.getters['learn/searchCond']
  const dataIndex = store.getters['learn/dataIndex']

  let likeList = []
  if (!searchCond) {
    await db
      .collection('learn')
      .orderBy('created_at', 'desc')
      .limit(define.DATA_MAX)
      .startAfter(dataIndex)
      .get()
      .then((snapShot) => {
        let size
        let sizeData = 0
        snapShot.forEach(async (doc) => {
          await db
            .collection('learn')
            .doc(doc.id)
            .collection('likedUsers')
            .get()
            .then((snapshotLike) => {
              likeList = []
              snapshotLike.forEach((docLike) => {
                likeList.push({ ...docLike.data() })
              })
              size = snapshotLike.size
            })
          let data = { id: doc.id, size: size, ...doc.data(), likeList: likeList }
          // state.list.push(data)
          resultList.push(data)
          sizeData += 1
          if (sizeData === define.DATA_MAX) {
            //   state.addDataFlg = true
            //   state.dataIndex = snapShot.docs[snapShot.docs.length - 1]
            store.dispatch('learn/updateAddDataFlg', true)
            store.dispatch('learn/updateDataIndex', snapShot.docs[snapShot.docs.length - 1])
          }
        })
      })
  } else {
    await db
      .collection('learn')
      .where('certification', '==', searchCond)
      .orderBy('created_at', 'desc')
      .startAfter(dataIndex)
      .limit(define.DATA_MAX)
      .get()
      .then((snapShot) => {
        let size
        let sizeData = 0
        snapShot.forEach(async (doc) => {
          await db
            .collection('learn')
            .doc(doc.id)
            .collection('likedUsers')
            .get()
            .then((snapshotLike) => {
              likeList = []
              snapshotLike.forEach((docLike) => {
                likeList.push({ ...docLike.data() })
              })
              size = snapshotLike.size
            })
          let data = { id: doc.id, size: size, ...doc.data(), likeList: likeList }
          resultList.push(data)
          sizeData += 1
          if (sizeData === define.DATA_MAX) {
            //   state.addDataFlg = true
            //   state.dataIndex = snapShot.docs[snapShot.docs.length - 1]
            store.dispatch('learn/updateAddDataFlg', true)
            store.dispatch('learn/updateDataIndex', snapShot.docs[snapShot.docs.length - 1])
          }
        })
      })
  }
  store.dispatch('learn/updateListNum', resultList.length)
  store.dispatch('learn/updateSkeletonDisp', false)
  learnList = resultList
  return resultList

  // state.skeletonDisp = false
}
