import firebase from '../lib/firebase'
import auth from '../lib/auth'
import db from '../lib/db'
import define from '../Define'

export const learn = {
  namespaced: true,
  state: {
    searchCond: null,
    list: [],
    skeletonDisp: false,
    addDataFlg: false,
    dataIndex: '',
    listNum: 0,
    noDataFlg: false,
  },
  getters: {
    searchCond: (state) => state.searchCond,
    list: (state) => state.list,
    skeletonDisp: (state) => state.skeletonDisp,
    addDataFlg: (state) => state.addDataFlg,
    listNum: (state) => state.listNum,
    noDataFlg: (state) => state.noDataFlg,
    dataIndex: (state) => state.dataIndex,
  },
  mutations: {
    async setCond(state, cond) {
      state.searchCond = cond
    },
    setList(state, list) {
      state.list = list
    },
    updateNoDataFlg(state, flg) {
      state.noDataFlg = flg
    },
    updatesSkeletonDisp(state, flg) {
      state.skeletonDisp = flg
    },
    updateListNum(state, num) {
      state.listNum = num
    },
    updateAddDataFlg(state, flg) {
      state.addDataFlg = flg
    },
    updateDataIndex(state, index) {
      state.dataIndex = index
    },
  },
  actions: {
    setCond({ commit }, cond) {
      commit('setCond', cond)
    },
    setList({ commit }, list) {
      commit('setList', list)
    },
    updateNoDataFlg({ commit }, flg) {
      commit('updateNoDataFlg', flg)
    },
    updateSkeletonDisp({ commit }, flg) {
      commit('updatesSkeletonDisp', flg)
    },
    updateListNum({ commit }, num) {
      commit('updateListNum', num)
    },
    updateAddDataFlg({ commit }, flg) {
      commit('updateAddDataFlg', flg)
    },
    updateDataIndex({ commit }, index) {
      commit('updateDataIndex', index)
    },
  },
}
