export default Object.freeze({
  CERTIFICATION_LIST: [
    { value: 'IP', label: 'ITパスポート', color: 'yellow darken-1', time: null },
    { value: 'SG', label: '情報セキュリティマネジメント', color: 'blue darken-1', time: null },
    { value: 'FE', label: '基本情報技術者', color: 'pink darken-1', time: null },
    { value: 'AP', label: '応用情報技術者', color: 'orange darken-3', time: null },
    { value: 'ST', label: 'ITストラテジスト', color: 'blue-grey darken-1', time: null },
    { value: 'SA', label: 'システムアーキテクト', color: 'red darken-2', time: null },
    { value: 'PM', label: 'プロジェクトマネージャ', color: 'purple darken-1', time: null },
    { value: 'NW', label: 'ネットワークスペシャリスト', color: 'amber darken-3', time: null },
    { value: 'DB', label: 'データベーススペシャリスト', color: 'green darken-1', time: null },
    { value: 'ES', label: 'エンベデッドシステムスペシャリスト', color: 'cyan darken-1', time: null },
    { value: 'SM', label: 'ITサービスマネージャ', color: 'grey darken-1', time: null },
    { value: 'AU', label: 'システム監査技術者', color: 'grey darken-3', time: null },
    { value: 'SC', label: '情報安全確保支援士', color: 'light-blue darken-4', time: null },
  ],
  MESSAGE_DEFAULT: {
    text: null,
    color: '',
    time: 4000,
  },
  MESSAGE_LOGIN_SUCCESS: {
    text: 'おかえりなさい！',
    color: 'success',
    time: 2000,
  },
  MESSAGE_LOGIN_SUCCESS_FIRST: {
    text: 'ようこそ！一緒に頑張りましょう！',
    color: 'success',
    time: 2000,
  },
  MESSAGE_LOGIN_FAILED: {
    text: 'ログインに失敗しました。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_LOGOUT_SUCCESS: {
    text: 'ログアウトしました。',
    color: 'success',
    time: 2000,
  },
  MESSAGE_DATA_CREATE_SUCCESS: {
    text: '学習を登録しました。今日もお疲れ様です！',
    color: 'success',
    time: 2000,
  },
  MESSAGE_DATA_CREATE_FAILED: {
    text: '運営の問題で失敗しました。時間を空けてもう一度お試しください。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_DATA_UPDATE_SUCCESS: {
    text: '学習を更新しました。',
    color: 'success',
    time: 2000,
  },
  MESSAGE_DATA_UPDATE_FAILED: {
    text: '運営の問題で失敗しました。時間を空けてもう一度お試しください。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_DATA_DELETE_SUCCESS: {
    text: '学習を削除しました。',
    color: 'success',
    time: 2000,
  },
  MESSAGE_DATA_DELETE_FAILED: {
    text: '運営の問題で失敗しました。時間を空けてもう一度お試しください。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_PROFILE_UPDATE_SUCCESS: {
    text: 'プロフィールを更新しました。',
    color: 'success',
    time: 2000,
  },
  MESSAGE_PROFILE_UPDATE_FAILED: {
    text: 'プロフィールの更新に失敗しました。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_DATA_VALIDATE_ERROR: {
    text: '入力内容に不備があります。',
    color: 'error',
    time: 2000,
  },
  MESSAGE_LIKE_NO_LOGIN: {
    text: 'いいねをするにはログインが必要です。',
    color: 'warning',
    time: 2000,
  },
  DATA_MAX: 30,
})
