import define from '../Define'
import firebase from '../lib/firebase'

const util = {
  getNowTime() {
    return firebase.firestore.FieldValue.serverTimestamp()
  },
  getCertificationLebel(value) {
    const certificationList = define.CERTIFICATION_LIST
    const resultObj = certificationList.find((v) => v.value === value)
    return resultObj !== undefined ? resultObj.label : ''
  },
  getCertificationColor(value) {
    const certificationList = define.CERTIFICATION_LIST
    const resultObj = certificationList.find((v) => v.value === value)
    return resultObj !== undefined ? resultObj.color : ''
  },
}

export default util
