<template>
  <div class="card-contained">
    <v-card class="pa-2 card-width mx-auto mt-3">
      <v-card-title
        >{{ cardData.time }}時間
        <v-row align="center" justify="end" v-show="this.optionDisp == true">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item link v-bind="attrs" v-on="on">
                    <v-list-item-title>情報を更新する</v-list-item-title>
                  </v-list-item>
                </template>
                <UpdateDataModal @closeDialog="closeDialog" @closeDialogAndUpdate="closeDialogAndUpdate" :cardData="this.cardData" />
              </v-dialog>

              <v-list-item link @click="deleteData(cardData.id)">
                <v-list-item-title>削除する</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <span class="font-weight-bold">
          {{ getDatetime(cardData.date) }}
        </span>
        <span class="font-weight-bold">
          {{ getCertificationLebel(cardData.certification) }}
        </span>
      </v-card-subtitle>
      <v-card-text>
        {{ cardData.memo }}
      </v-card-text>
      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-img class="elevation-6" alt="" :src="cardData.image"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ cardData.name }}</v-list-item-title>
          </v-list-item-content>

          <v-row align="center" justify="end">
            <v-btn icon @click="likedDelete(cardData.id)" v-if="this.isLike == true">
              <v-icon class="mr-1" color="accent"> mdi-heart </v-icon>
            </v-btn>
            <v-btn icon v-else @click="likedCreate(cardData.id)">
              <v-icon class="mr-1"> mdi-heart </v-icon>
            </v-btn>
            <span class="subheading mr-2">{{ this.likeNum }} </span>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import define from '../Define'
  import util from '../utils/util'
  import { mapActions } from 'vuex'
  // import firebase from 'firebase'
  // import 'firebase/firestore'
  import firebase from '../lib/firebase'
  import db from '../lib/db'
  import Swal from 'sweetalert2'
  import UpdateDataModal from '../components/UpdateDataModal'
  export default {
    props: {
      cardData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        list: [],
        isLike: false,
        likeNum: 0,
        dialog: false,
        optionDisp: false,
      }
    },
    components: {
      UpdateDataModal,
    },
    created() {
      const id = this.cardData.id
      const list = this.cardData.likeList
      const uid = this.$store.getters['user/uid']
      if (id && uid) {
        let get = list.find((d) => d.user_id == uid)
        this.isLike = !get ? false : true
        if (uid === this.cardData.uid) {
          this.optionDisp = true
        }
      } else {
        this.isLike = false
      }
      this.likeNum = this.cardData.size
    },
    computed: {
      liked: {
        async get(id) {
          const uid = this.$store.getters['user/uid']
          const learnRef = db.collection('learn').doc(id)
          if (uid) {
            await db
              .doc(learnRef)
              .collection('likedUsers')
              .doc(uid)
              .get()
              .then((snapshot) => {
                return snapshot.empty ? false : true
              })
          }
          return false
        },
      },
    },
    methods: {
      ...mapActions('learn', ['setCond']),
      ...mapActions('views', ['setSnack']),
      async getList() {
        return await this.$store.getters['learn/getLearnList']
      },
      getCertificationLebel(value) {
        return util.getCertificationLebel(value)
      },
      getDatetime(date) {
        if (!date) {
          return ''
        } else {
          return new Date(date.seconds * 1000 + date.nanoseconds / 100000).toISOString().substr(0, 10)
        }
      },
      async likedCreate(id) {
        const firebaseInfo = firebase.firestore()
        const batch = db.batch()
        const learnRef = db.collection('learn').doc(id)

        const uid = this.$store.getters['user/uid']
        const name = this.$store.getters['user/displayName']
        const nowDatetime = util.getNowTime()

        if (uid) {
          batch.set(
            db
              .doc(learnRef.path)
              .collection('likedUsers')
              .doc(uid),
            {
              user_id: uid,
              created_at: nowDatetime,
              name: name,
            }
          )

          await batch.commit()
          this.isLike = true
          this.likeNum = this.likeNum + 1
        } else {
          this.setSnack(define.MESSAGE_LIKE_NO_LOGIN)
        }
      },
      async likedDelete(id) {
        const firebaseInfo = firebase.firestore()
        const batch = db.batch()
        const learnRef = db.collection('learn').doc(id)

        const uid = this.$store.getters['user/uid']

        if (uid) {
          batch.delete(
            db
              .doc(learnRef.path)
              .collection('likedUsers')
              .doc(uid)
          )

          await batch.commit()
          this.isLike = false
          this.likeNum = this.likeNum - 1
        } else {
          this.setSnack(define.MESSAGE_LIKE_NO_LOGIN)
        }
      },
      isLiked(id, list) {
        const uid = this.$store.getters['user/uid']
        if (id && uid) {
          let get = list.find((d) => d.user_id == uid)
          return !get ? false : true
        } else {
          return false
        }
      },
      deleteData(id) {
        Swal.fire({
          title: '削除します。元には戻せません。',
          text: 'よろしいでしょうか？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#194292',
        }).then((result) => {
          if (result.isConfirmed) {
            db.collection('learn')
              .doc(id)
              .delete()
              .then(() => {
                this.setSnack(define.MESSAGE_DATA_DELETE_SUCCESS)
                this.setCond(this.$store.getters['learn/searchCond'])
                this.$root.$refs.Home.updateList()
              })
              .catch(() => {
                this.setSnack(define.MESSAGE_DATA_DELETE_FAILED)
              })
          }
        })
      },
      closeDialog() {
        this.dialog = false
      },
      closeDialogAndUpdate() {
        this.dialog = false
        //home画面にあるmethodを実行する
        this.$root.$refs.Home.updateList()
      },
    },
  }
</script>

<style>
  .card-width {
    width: 92vw;
  }
</style>
