import Vue from 'vue'
import Vuex from 'vuex'

import { user } from './user'
import { learn } from './learn'
import { views } from './views'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    learn,
    views,
  },
  plugins: [createPersistedState()],
})
