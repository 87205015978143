<template>
  <v-card>
    <v-card-title class="headline grey lighten-2">
      学習時間を登録する
    </v-card-title>

    <v-form ref="form">
      <v-card-text>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" label="学習日" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field v-model="time" outlined label="学習時間" placeholder="単位 : 時間" class="mt-4" :rules="timeRules"></v-text-field>
        <v-textarea v-model="memo" outlined label="メモ" :rules="memoRules" counter="140"></v-textarea>
      </v-card-text>

      <v-card-text> 学習した資格 : {{ learnLabel }} </v-card-text>

      <v-card-actions>
        <v-btn text　@click="show = !show">
          学習した資格を変更する
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <v-card-text v-show="show">
          <v-divider></v-divider>
          <v-radio-group v-model="radioValue">
            <v-radio v-for="data in radioList" :key="data.value" :label="data.label" :value="data.value" @change="changeStudyQualification(data.value, data.label)"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-expand-transition>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions v-show="isAuth">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeDialog">
        閉じる
      </v-btn>
      <v-btn color="primary" text @click="saveData">
        投稿する
      </v-btn>
    </v-card-actions>
    <v-card-actions v-show="!isAuth">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeDialog">
        ログインすると学習を記録できます。
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import define from '../Define'
  import firebase from '../lib/firebase'
  import db from '../lib/db'
  import util from '../utils/util'
  import { mapActions } from 'vuex'
  export default {
    data: function() {
      return {
        radioValue: 'IP',
        learnLabel: 'ITパスポート',
        radioList: define.CERTIFICATION_LIST,
        show: false,
        time: null,
        memo: '',
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        menu: false,
        timeRules: [(v) => !!v || '入力は必須です', (v) => /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) || '整数、少数(少数第二位まで)のみ入力可能です'],
        memoRules: [(v) => !v || v.length <= 140 || 'メモは140文字まで入力可能です'],
      }
    },
    created() {
      const certification = this.$store.getters['user/learn']
      this.radioValue = certification
      this.learnLabel = util.getCertificationLebel(certification)
    },
    computed: {
      isAuth() {
        return this.$store.getters['user/isAuth']
      },
    },
    methods: {
      initState() {
        return {
          radioValue: 'IP',
          learnLabel: 'ITパスポート',
          radioList: define.CERTIFICATION_LIST,
          show: false,
          time: null,
          memo: '',
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          menu: false,
          timeRules: [(v) => !!v || '入力は必須です', (v) => /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) || '整数、少数(少数第二位まで)のみ入力可能です'],
          memoRules: [(v) => !v || v.length <= 140 || 'メモは140文字まで入力可能です'],
        }
      },
      ...mapActions('views', ['setSnack']),
      ...mapActions('learn', ['setCond']),
      /**
       * 当モーダルのclose
       */
      closeDialog() {
        this.initData()
        this.$emit('closeDialog')
      },
      /**
       * ラジオボタンで選択した資格にラベルを変更する
       */
      changeStudyQualification(value, label) {
        this.learnLabel = label
        this.radioValue = value
      },
      async saveData() {
        if (!this.isAuth) return
        if (this.$refs.form.validate()) {
          //必要ユーザー情報を取得
          const uid = this.$store.getters['user/uid']
          const photoURL = this.$store.getters['user/photoURL']
          const displayName = this.$store.getters['user/displayName']
          const nowDatetime = util.getNowTime()
          const dateTime = firebase.firestore.Timestamp.fromDate(new Date(this.date))

          //バリデーションチェック通過
          await db
            .collection('learn')
            .add({
              uid: uid,
              name: displayName,
              image: photoURL,
              certification: this.radioValue,
              time: this.time,
              memo: this.memo,
              date: dateTime,
              created_at: nowDatetime,
              updated_at: nowDatetime,
            })
            .then(() => {
              this.setSnack(define.MESSAGE_DATA_CREATE_SUCCESS)
              this.initData()
              //データ再取得
              this.$emit('closeDialogAndUpdate')
            })
            .catch(() => {
              //エラーハンドリング
              this.setSnack(define.MESSAGE_DATA_CREATE_FAILED)
            })
        } else {
          //バリデーションチェック失敗
          // 処理検討
          this.setSnack(define.MESSAGE_DATA_VALIDATE_ERROR)
        }
      },
      initData() {
        // this.time = ''
        // this.memo = ''
        // this.$refs.form.reset()
        // Object.assign(this.$data, this.initState())
        this.time = null
        this.memo = ''
      },
    },
  }
</script>
