import db from './db'
import store from '../store/index'
import define from '../Define'

export const getAlertList = async () => {
  let alertList = []
  const uid = store.getters['user/uid']
  let badgeShow = false
  if (!uid) {
    alertList = []
    store.dispatch('user/updateBadgeShow', false)
  } else {
    // 一旦リセット
    alertList = []
    store.dispatch('user/updateBadgeShow', false)
    await db
      .collection('user')
      .doc(uid)
      .collection('alert')
      .orderBy('created_at', 'desc')
      .limit(30)
      .get()
      .then((snapShot) => {
        snapShot.forEach(async (doc) => {
          const data = doc.data()
          alertList.push({ id: doc.id, ...doc.data() })
          if (!badgeShow && !data.is_read) {
            store.dispatch('user/updateBadgeShow', true)
            badgeShow = !badgeShow
          }
        })
      })
  }
  return alertList
}
